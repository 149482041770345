.App {
    display: flex;
  flex-direction: row;
  height: 150vh;
  background-color: #222323;
}

.App-nav{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1A1919;
  width: 20vw;
  padding-left: 20px;
  padding-right: 20px;
}

.footer{
  text-align: center;
  color: white;
  padding-bottom: 10px;
}

.Navlink {
  color: white;
  text-decoration: none;
  list-style-type: none;
  font-size: 1.5rem;
}

.Navlink:hover{
  color: darkgrey;
}

ul {
  padding: 0;
}

li{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-bottom: #222323 solid 1px;
}

.Error {
  display: flex;
  height: 75vh;
  margin: 50px;
  align-items: center;
  justify-content: center;
  color:white;
}

.leaflet-bottom{
  visibility: hidden;
}

.leaflet-container{
  height: 100vh;
  width: 80vw;
}
.info{
  color: azure;
  font-size: 25px;
}

